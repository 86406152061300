import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/front/home'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
    },
    {
        path: '/front',
        name: 'Front',
        component: () => import('../views/front/Front'),
        children: [
            {path: 'person', name: '个人信息', component: () => import('../views/front/Person.vue')},
            {path: 'password', name: '修改密码', component: () => import('../views/front/Password.vue')},
            {
                path: 'home',
                name: 'FrontHome',
                component: () => import('../views/front/Home.vue')
            },
            {
                path: 'homeDetail',
                name: 'HomeDetail',
                component: () => import('../views/front/HomeDetail')
            },
            {
                path: 'myBook',
                name: 'MyBook',
                component: () => import('../views/front/MyBook')
            },
            {
                path: 'myDriftRecordBook',
                name: 'MyDriftRecordBook',
                component: () => import('../views/front/MyDriftRecordBook')
            },
            {
                path: 'article',
                name: 'Article',
                component: () => import('../views/front/Article.vue')
            },
            {
                path: 'myBookSelf',
                name: 'MyBookSelf',
                component: () => import('../views/front/MyBookSelf.vue')
            },
            {
                path: 'discuss',
                name: 'Discuss',
                component: () => import('../views/front/Discuss.vue')
            },
            {
                path: 'discussDetail',
                name: 'DiscussDetail',
                component: () => import('../views/front/DiscussDetail.vue')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// 重置路由
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        routes
    })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
    const storeMenus = localStorage.getItem("menus");
    if (storeMenus) {
        // 拼装动态路由
        const manageRoute = {
            path: '/manage', name: 'Manage', redirect: "/home", component: () => import('../views/Manage.vue'),
            children: [

                {path: 'person', name: '个人信息', component: () => import('../views/Person.vue')},
                {path: 'password', name: '修改密码', component: () => import('../views/Password.vue')},
            ]
        }
        const menus = JSON.parse(storeMenus)
        menus.forEach(item => {
            if (item.path) {  // 当且仅当path不为空的时候才去设置路由
                let itemMenu = {
                    path: item.path,
                    name: item.name,
                    component: () => import('../views/' + item.pagePath + '.vue')
                }
                manageRoute.children.push(itemMenu)
            } else if (item.children.length) {
                item.children.forEach(item => {
                    if (item.path) {
                        let itemMenu = {
                            path: item.path,
                            name: item.name,
                            component: () => import('../views/' + item.pagePath + '.vue')
                        }
                        manageRoute.children.push(itemMenu)
                    }
                })
            }
        })
        console.log(manageRoute)

        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        if (!currentRouteNames.includes('Manage')) {
            console.log("no")

            // 动态添加到现在的路由对象中去
            router.addRoute(manageRoute)
        }

        console.log(router.getRoutes().map(v => v))
    }
}

// 每次刷新页面都要重新设置路由，否则路由就会被重置
setRoutes()

router.beforeEach((to, from, next) => {
    localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
    store.commit("setPath")
    if (!to.matched.length) {
        const menus = localStorage.getItem("menus")
        if (!menus) {
            next("/login")
        } else {
            next("/404")
        }
    } else {
        next()
    }
})

export default router
